import React from "react";
import { Box, Link, Container, Grid, Typography, Stack } from "@mui/material";
import { graphql, Link as GatsbyLink } from "gatsby";

import { AlternativeHeader, NAV_BAR_HEIGHT } from "../Layout/Header";
import Footer, { ContactSection } from "../Layout/Footer";
import Theme, { myTheme } from "../utils/Theme";
import { getSrc } from "gatsby-plugin-image";
import { Artwork, QueryResult } from "../utils/types";
import getGatsbyImage from "../utils/getGatsbyImage";
import { getSeoLinks, getSeoMetas } from "../components/SeoHelper";
import { Helmet } from "react-helmet";

export const query = graphql`
  {
    imageOne: allImageSharp(
      filter: { original: { src: { regex: "/image0/i" } } }
    ) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    lisa: allImageSharp(filter: { original: { src: { regex: "/lisa/i" } } }) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    frighten: allImageSharp(
      filter: { original: { src: { regex: "/frighten/i" } } }
    ) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    therian: allImageSharp(
      filter: { original: { src: { regex: "/therian/i" } } }
    ) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    love: allImageSharp(filter: { original: { src: { regex: "/love/i" } } }) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    paola: allImageSharp(filter: { original: { src: { regex: "/pabs/i" } } }) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
  }
`;

function getArtworksFromData(data: {
  frighten: QueryResult;
  lisa: QueryResult;
  imageOne: QueryResult;
  therian: QueryResult;
  love: QueryResult;
  paola: QueryResult;
}): Artwork[] {
  return [
    {
      src: getSrc(getGatsbyImage(data.frighten)) || "",
      title: "Frighten",
      description: "Acrylics on panel,Violence, 2016",
    },
    {
      src: getSrc(getGatsbyImage(data.therian)) || "",
      title: "Therian (restored)",
      description: "Actylics on canvas, the Three Demons, 2021",
    },
    {
      src: getSrc(getGatsbyImage(data.imageOne)) || "",
      title: "First Drawing",
    },
    {
      src: getSrc(getGatsbyImage(data.lisa)) || "",
      title: "Lisa Maria Angrino",
    },
    {
      src: getSrc(getGatsbyImage(data.love)) || "",
      title: "Love, 2022",
    },
    {
      src: getSrc(getGatsbyImage(data.paola)) || "",
      title: "Paola",
    },
  ];
}

const Item: React.FC<{ bgUrl: string; complex?: boolean }> = ({
  children,
  bgUrl,
  complex,
}) => {
  return (
    <Box
      height="300px"
      overflow="hidden"
      border={1}
      sx={{
        backgroundImage: ` url(${bgUrl})`,
        backgroundSize: "cover",
      }}
    >
      <Box
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          bgcolor: "#0000003d",
          "&:hover": {
            backgroundColor: "black",
          },
        }}
      >
        <Box>
          {complex ? (
            children
          ) : (
            <Typography
              variant="h2"
              color="white"
              component={"p"}
              textAlign="center"
            >
              {children}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const IndexPage = ({ data }: any) => {
  const artworks = getArtworksFromData(data);
  return (
    <Theme>
      <Helmet
        title="Home"
        titleTemplate="%s · Lisa Angrino"
        meta={getSeoMetas({
          title: "Home",
          path: "",
          description:
            "Lisa Angrino is a visual artist and philosopher from Colombia. Discover her paintings and drawings, more about her story and how to contact her. While she experiments with materials and media, her thematic focus has always been the interpretation, translation and transmission of dreams, feelings, and the connection between the real and the unreal.",
        })}
        link={getSeoLinks({ path: "" })}
      />
      <Box
        display={"flex"}
        flexDirection="column"
        justifyContent="space-between"
        height={{ xs: "default", sm: "100vh" }}
      >
        <AlternativeHeader />
        <Container
          sx={{
            paddingBottom: myTheme.spacing(3),
            marginTop: `calc(${NAV_BAR_HEIGHT} +  0.35em + 8px)`,
            // height: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Link
                to="/paintings"
                component={GatsbyLink as any}
                underline="none"
                color="inherit"
              >
                <Item bgUrl={artworks[1].src}>paintings</Item>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Link
                to="/drawings"
                component={GatsbyLink as any}
                color="inherit"
                underline="none"
              >
                <Item bgUrl={artworks[2].src}>drawings</Item>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Link
                to="/about"
                component={GatsbyLink as any}
                underline="none"
                color="inherit"
              >
                <Item bgUrl={artworks[3].src}>about</Item>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Link
                to="/feelings"
                component={GatsbyLink as any}
                underline="none"
                color="inherit"
              >
                <Item bgUrl={artworks[4].src}>feelings</Item>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Link
                to="/portraits"
                component={GatsbyLink as any}
                underline="none"
                color="inherit"
              >
                <Item bgUrl={artworks[5].src}>Portraits</Item>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Item bgUrl={artworks[0].src} complex>
                <Stack spacing={2}>
                  <Typography variant="h2" color="white" component={"p"}>
                    contact
                  </Typography>
                  <ContactSection color="white" />
                </Stack>
              </Item>
            </Grid>
          </Grid>
        </Container>

        <Footer />
      </Box>
    </Theme>
  );
};

export default IndexPage;
